import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import PageTitle from 'components/directus/page-title/PageTitle';
import Text from 'components/directus/text/Text';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus';
import { PageProps } from 'utils/gatsby.interface';

import s from 'pages-styles/terms-and-conditions/index.scss';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
    const slug = 'terms-and-conditions';
    const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);

    return (
        <>
            <Helmet>
                <title>{node.title}</title>
                <meta name="title" content={node.title} />
                <meta name="description" content={node.description} />
                <meta name="robots" content={node.robots} />
                <link rel="canonical" href={node.canonical} />
            </Helmet>

            <Wrapper className={s.terms}>
                <Section bottom={0}>
                    <PageTitle slug={slug} />
                </Section>
                <Section>
                    <Text slug={slug} />
                </Section>
            </Wrapper>
        </>
    );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "terms-and-conditions" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
